import React from "react";
import ReactDOM from "react-dom";
import ErrorBoundary from "../error-boundary/ErrorBoundary";
//import headerData from "./header-data.json";
//import navDataLocal from "./primary-navigation/navigation.json";
//import secondaryNavMobDataLocal from "../secondary-nav-mob/secondary-nav-mob.json";
//import breadcrumbsDataLocal from "../breadcrumbs/breadcrumbs-data.json";
import PrimaryNavigation from "./primary-navigation/PrimaryNavigation";
import SecondaryNavMob from "../secondary-nav-mob/SecondaryNavMob";
import HamburgerNav from "../hamburger-nav/hamburgerNav";
import TopMenu from "./top-menu/TopMenu";
import Heading from "../heading/Heading";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hamburgerOpen: false,
      searchOpen: false,
      searchTerm: "",
      globalMenuExpanded: false,
      autoCompleteSuggestions: false
    };

    this.toggleSearch = this.toggleSearch.bind(this);
    this.toggleHamburger = this.toggleHamburger.bind(this);
    this.updateSearchTerm = this.updateSearchTerm.bind(this);
    this.updateGlobalMenuExpanded = this.updateGlobalMenuExpanded.bind(this);
    this.searchAutocomplete = this.searchAutocomplete.bind(this);
    this.hideAutocomplete = this.hideAutocomplete.bind(this);
    this.escFunction = this.escFunction.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.searchOpen) {
      this._mobSearch.focus();
    }
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.hideAutocomplete();
    }
  }

  toggleSearch() {
    this.setState(prevState => ({
      searchOpen: !prevState.searchOpen,
      hamburgerOpen: false
    }));
  }

  toggleHamburger() {
    this.setState(prevState => ({
      hamburgerOpen: !prevState.hamburgerOpen,
      searchOpen: false
    }));
  }

  updateSearchTerm(e) {
    this.setState({
      searchTerm: e.target.value,
      autoCompleteSuggestions: false
    });
    if (e.target.value.length >= 3) {
      this.searchAutocomplete();
    }
  }

  updateGlobalMenuExpanded(childData) {
    this.setState({
      globalMenuExpanded: childData
    });
  }

  searchAutocomplete(e) {
    //fetch funnelback autocomplete endpoint
    let endpoint =
      "https://djsir-search.funnelback.squiz.cloud/s/suggest.json?collection=djsir~sp-business-victoria&show=10&sort=0&alpha=.5&fmt=json++&partial_query=" +
      this.state.searchTerm;
    fetch(endpoint)
      .then(response => response.json())
      .then(data => {
        if (data.length != 0) {
          this.setState({
            autoCompleteSuggestions: data
          });
        } else {
          this.setState({
            autoCompleteSuggestions: false
          });
        }
      });
  }

  hideAutocomplete(e) {
    //only hide suggestions if the current target is not one of the suggestions. This is to handle onblur
    if (
      typeof e == "undefined" ||
      e.relatedTarget == null ||
      e.relatedTarget.className != "suggestion"
    ) {
      this.setState({
        autoCompleteSuggestions: false
      });
    }
  }

  renderSuggestions() {
    if (this.state.autoCompleteSuggestions) {
      return (
        <ul className="suggestions">
          {this.state.autoCompleteSuggestions.map(index => (
            <li key={index.disp}>
              <a
                className="suggestion"
                href={`https://business.vic.gov.au/search-results?query=${index.disp}`}
                tabindex="0"
              >
                {index.disp}
              </a>
            </li>
          ))}
        </ul>
      );
    }
  }

  render() {
    const logoStructuredData = {
      "@context": "https://schema.org",
      "@type": "Organization",
      url: "https://business.vic.gov.au",
      logo:
        "https://business.vic.gov.au" +
        require("../../images/bv-logo-print.svg")
    };
    return (
      <header>
        <div className="container-fluid">
          <div className="row">
            {/*hamburger nav - xs and sm only*/}
            <div className="hamburger-button-container col-2 d-md-none">
              <button
                className={` ${
                  this.state.hamburgerOpen ? "" : "hamburger-button"
                }`}
                type="button"
                aria-label="Open Menu"
                tabIndex="0"
                onClick={this.toggleHamburger}
              >
                {" "}
                {this.state.hamburgerOpen ? (
                  <i className="fa fa-times"></i>
                ) : (
                  ""
                )}
              </button>
            </div>
            <HamburgerNav
              navData={this.props.navData.navigation}
              hamburgerOpen={this.state.hamburgerOpen}
            />
            <script type="application/ld+json">
              {JSON.stringify(logoStructuredData)}
            </script>
            {/*print logo*/}
            <div className="col-12  d-none d-print-block">
              <a href="https://business.vic.gov.au">
                <img
                  src={require("../../images/bv-logo-print.svg")}
                  className="bv-logo"
                  alt="Business Victoria logo"
                  style={{ height: "3.5rem" }}
                />
              </a>
            </div>
            {/*screen logo*/}
            <div className="col-8 col-md-4  text-center text-md-left  d-print-none">
              <a href="https://business.vic.gov.au" className="d-inline-block">
                <img
                  src={require("../../images/bv-logo.svg")}
                  className="bv-logo"
                  alt="Business Victoria logo"
                />
              </a>
            </div>
            {/* top nav - md and above only*/}
            <div className="top-nav d-none d-md-block col-8">
              <ul>
                {this.props.navData.navigation.map(index => (
                  <TopMenu navItem={index} key={index.assetID} />
                ))}
              </ul>
            </div>
            {/* primary nav - md and above only*/}
            <div className="primary-nav col-12">
              <ul>
                {this.props.navData.navigation.map(index => (
                  <PrimaryNavigation
                    navItem={index}
                    globalMenuExpanded={this.state.globalMenuExpanded}
                    updateParent={this.updateGlobalMenuExpanded}
                    key={index.assetID}
                  />
                ))}
              </ul>
              {/*desktop search*/}
              <form
                autoComplete="off"
                action="https://business.vic.gov.au/search-results"
                className="search-container"
                onBlur={this.hideAutocomplete}
              >
                <input
                  className="search-field"
                  name="query"
                  type="text"
                  aria-label="Search this website"
                  placeholder="Search"
                  onChange={this.updateSearchTerm}
                  value={this.state.searchTerm}
                />
                {this.renderSuggestions()}
                <button
                  className="search-button fa fa-search"
                  type="submit"
                  aria-label="Execute Search"
                ></button>
              </form>
            </div>
            {/*mobile search*/}
            <div className="mob-search-button-container d-md-none col-2">
              <button
                className={`mob-search-button`}
                type="submit"
                aria-label="Open Search"
                onClick={this.toggleSearch}
              >
                {this.state.searchOpen ? (
                  <i className="fa fa fa-times"></i>
                ) : (
                  <i className="fa fa-search"></i>
                )}
              </button>
            </div>
            <form
              autoComplete="off"
              action="https://business.vic.gov.au/search-results"
              className={`container mob-search-container d-md-none ${
                this.state.searchOpen ? "open" : ""
              }`}
              onBlur={this.hideAutocomplete}
            >
              <input
                className="search-field"
                name="query"
                type="text"
                aria-label="Search this website"
                placeholder="Search"
                onChange={this.updateSearchTerm}
                value={this.state.searchTerm}
                ref={input => (this._mobSearch = input)}
              />
              {this.renderSuggestions()}
              <button
                className="search-button fa fa-search"
                type="submit"
                aria-label="Execute Search"
              ></button>
            </form>
          </div>
        </div>
        <Heading
          Data={this.props.headingData}
          linkBoxData={this.props.linkBoxData}
          breadcrumbsData={this.props.breadcrumbsData}
          secondaryNavMobData={this.props.secondaryNavMobData}
        />
      </header>
    );
  }
}

/* var navData;

if (typeof navDataCMS != "undefined") {
  navData = navDataCMS;
} else {
  navData = navDataLocal;
}



var breadcrumbsData;

if (typeof breadcrumbsDataCMS != "undefined") {
  breadcrumbsData = breadcrumbsDataCMS;
} else {
  breadcrumbsData = breadcrumbsDataLocal;
} */

/* var secondaryNavMobData;

if (typeof secondaryNavDataCMS != "undefined") {
  secondaryNavMobData = secondaryNavDataCMS;
} else {
  secondaryNavMobData = secondaryNavMobDataLocal;
} */

if (document.querySelector("#header-react")) {
  ReactDOM.render(
    <ErrorBoundary>
      <Header
        navData={navDataCMS}
        headingData={headingData}
        breadcrumbsData={breadcrumbsDataCMS}
        secondaryNavMobData={secondaryNavDataCMS}
      />
    </ErrorBoundary>,
    document.getElementById("header-react")
  );
}

export default Header;
